<template>
	<div id="page-login" class="flex w-full layout--middle">
		<div class="sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center" style="max-width: 500px">
			<vx-card class="shadow-none">
				<div class="h-full mx-auto self-center bg-white bg-dark">
					<div class="iframe-holder">
						<iframe
							src="https://assets.sinao.fr/register/"
							allowfullscreen
							scrolling="no"
							frameborder="0"
						></iframe>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style>
	.iframe-holder {
		width: 100%;
		height: 100%;
	}

	.iframe-holder iframe {
		height: 420px;
		width: 100%;
	}
</style>
